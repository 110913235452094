.glassy {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(27, 66, 78, 0.1);
}

.gradient {
  background: rgb(226, 251, 248);
  background: linear-gradient(
    180deg,
    rgba(226, 251, 248, 1) 0%,
    rgba(255, 255, 255, 1) 46%
  );
}

.stroke {
  /* text stroke */
  text-shadow: 0 0 10px rgba(255, 255, 2555, 1);
}



.social {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* image from public folder*/
  background-image: url("../assets/home/social.webp");
}

.socialsm {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* image from public folder*/
  background-image: url("../assets/home/socialsm.webp");
} 

.about {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* image from public folder*/
  background-image: url("../assets/about/about.webp");
}

.circle {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* image from public folder*/
  background-image: url("../assets/directory/circle.svg");
}

.directory {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  /* image from public folder*/
  background-image: url("../assets/directory/directory.png");
}

.about-justis {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* image from public folder*/
  background-image: url("../assets/about/fan.webp");
}

.home-about {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* image from public folder*/
  background-image: url("../assets/home/about.webp");
}

.dontFuckingOverlap {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.bg1 {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* image from public folder*/
  background-image: url("../assets/home/banner.webp");
}

.bg2 {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* image from public folder*/
  background-image: url("../assets/home/home.webp");
}

.team {
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 075) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.75)),
    color-stop(100%, rgba(0, 0, 0, 0.1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.75) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.75) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.75) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.75) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

/* .bg3 {
  width: 100%;
  height: auto;
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;


  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../assets/bg3.jpg");
} */

.scroller {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  margin-top: 20px;
}

.scroller .card {
  flex: 0 0 auto;
}

.scroller::-webkit-scrollbar {
  display: none;
}

.bounce {
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

/* Scroll down indicator (bouncing) */
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -moz-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    -o-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  width: 20rem;
  height: 25rem;
  border: 1px solid #f1f1f1;
  margin-bottom: 5rem;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: start;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

/* scroll indicator style */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border: solid rgba(73, 167, 80, 0);
  background-clip: padding-box;
  background-color: rgba(73, 167, 80, 0.3);
  border-radius: 10px;
  box-shadow: inset -1px -1px 0px rgba(73, 167, 80, 0.5),
    inset 1px 1px 0px rgba(73, 167, 80, 0.5);
  -webkit-border-radius: 10px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(73, 167, 80, 0.5),
    inset 1px 1px 0px rgba(73, 167, 80, 0.5);
}

::-webkit-scrollbar-button {
  display: none;
  width: 0;
  height: 0;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

.select {
  position: relative;
  min-width: 200px;
}
.select svg {
  position: absolute;
  right: 12px;
  top: calc(50% - 3px);
  width: 10px;
  height: 6px;
  stroke-width: 2px;
  stroke: #9098a9;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  pointer-events: none;
}
.select select {
  -webkit-appearance: none;
  padding: 7px 40px 7px 12px;
  width: 100%;
  border: 1px solid #e8eaed;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 1px 3px -2px #9098a9;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  transition: all 150ms ease;
}
.select select:required:invalid {
  color: #5a667f;
}
.select select option {
  color: #223254;
}
.select select option[value=""][disabled] {
  display: none;
}
.select select:focus {
  outline: none;
  border-color: #07f;
  box-shadow: 0 0 0 2px rgba(0, 119, 255, 0.2);
}
.select select:hover + svg {
  stroke: #07f;
}
.sprites {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}

.menu:hover {
  font-size: 1.1rem;
}
